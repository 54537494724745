@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500&family=Roboto:wght@100;300;400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
	font-family: 'SFProTextLight';
	src: url('./assets/fonts/SFProText-Light.woff') format('woff'), url('./assets/fonts/SFProText-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SFProTextr';
	src: url('./assets/fonts/SFProText-Regular.woff2') format('woff2'), url('./assets/fonts/SFProText-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'NunitoSansNormal';
	src: url('./assets/fonts/NunitoSans-Regular.ttf') format('truetype'), url('./assets/fonts/NunitoSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'SFProTextMedium';
	src: url('./assets/fonts/SFProText-Medium.woff2') format('woff2'), url('./assets/fonts/SFProText-Regular.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SFProTextr';
	src: url('./assets/fonts/SFProText-Regular.woff2') format('woff2'), url('./assets/fonts/SFProText-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'NunitoSansNormal';
	src: url('./assets/fonts/NunitoSans-Regular.ttf') format('truetype'), url('./assets/fonts/NunitoSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'SFProTextMedium';
	src: url('./assets/fonts/SFProText-Medium.woff2') format('woff2'), url('./assets/fonts/SFProText-Regular.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'SFProTextSemiBold';
	src: url('./assets/fonts/SFProText-Semibold.woff2') format('woff2'), url('./assets/fonts/SFProText-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'SFProTextBold';
	src: url('./assets/fonts/SFProText-Bold.woff2') format('woff2'), url('./assets/fonts/SFProText-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'MontserratBold';
	src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype'), url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'MontserratBold';
	src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype'), url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'LatoRegular';
	src: url('./assets/fonts/Lato-Regular.ttf') format('truetype'), url('./assets/fonts/Lato-Regular.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'LatoBlack';
	src: url('./assets/fonts/Lato-Black.ttf') format('truetype'), url('./assets/fonts/Lato-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}
.dashboard-bg::before {
	content: '';
	background-color: bisque;
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: -1;
}

body {
	background-color: #fff7e4;
}

table th:first-child {
	border-radius: 10px 0 0 10px;
}
table th:last-child {
	border-radius: 0 10px 10px 0;
}

.page-wrapper {
	min-height: calc(100vh - 80px);
}
@media screen and (max-width: 1200) {
	.page-wrapper {
		min-height: auto;
	}
}

.nav {
	position: sticky !important;
}

.left-0.switch {
	left: 0 !important;
	position: unset;
}

#dashboard_table th:first-child {
	border-radius: 10px 0px 0px 0px;
}
#dashboard_table th:last-child {
	border-radius: 0px 10px 0px 0px;
}
/* The switch - the box around the slider */
.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	left: 50%;
}
.switchToggle{
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #856544;
}

input:focus + .slider {
	box-shadow: 0 0 1px #856544;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}
label {
	font-family: 'SFProTextr';
	font-weight: 400;
	font-size: 14px;
	margin-bottom: 16px;
}
/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

/* Vertical Line */
.vl {
	border-left: 1px solid grey;
	height: 5rem;
}

/* width */
::-webkit-scrollbar {
	width: 0.8rem;
	height: 0.8rem;
}

/* Track */
::-webkit-scrollbar-track {
	background: #fff;
}
.sidebar::-webkit-scrollbar-track {
	background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #856544;
	border-radius: 7px;
}

/* .formField {
  @apply w-full border-b border-gray-400 outline-none text-gray-900 mb-3 py-4;
}
.formField:not(input[type='file']) {
  height: 18px;
} */
.formField {
	@apply w-full h-[2.5rem] border-b border-b-gray-400 outline-none rounded-md p-1 text-gray-900;
}
.sidebarLink {
	@apply flex  items-center p-1 text-base font-normal  text-gray-900 rounded-lg dark:text-white hover:text-gray-400;
}

.formFields {
	@apply w-full h-[2.5rem] border-b-2 border-b-red-700 outline-none rounded-md p-1 text-gray-900;
}
button[type='submit'] {
	font-weight: 700;
	font-family: 'SFProTextBold';
}
/* .sidebarLink {
  @apply flex  items-center p-1 text-base font-normal  text-gray-900 rounded-lg dark:text-white hover:text-gray-400;
} */

/* .formFields {
  @apply w-full h-[2.5rem] border border-red-700 outline-none rounded-md p-1 text-gray-900;
} */
/* *{
  overflow-x: hidden;

} */
.sidebarOverflowControl{
	overflow-x: hidden !important;
}
.change-switch-btn .switch {
	position: unset;
	left: 0;
	margin: 0;
}
.change-switch-btn.right-5 {
	right: 0;
}

.settingScroll::-webkit-scrollbar {
	display: none;
}

/* table{
  zoom: 90%;
} */
/* .mainChild {
  zoom: 90%;
} */
.simpleModal {
	zoom: 90%;
}

.businessDetail::-webkit-scrollbar {
	display: none;
}
.driver_details::-webkit-scrollbar {
	display: none;
}
.dashboard-switch-wrapper .switch {
	left: 0% !important;
}
#primary-table tbody tr:nth-child(even) {
	background: #fff7e4;
	border-radius: 8px;
}
#primary-table tbody tr:nth-child(even) > :first-child {
	border-radius: 10px 0 0 10px;
}
#primary-table tbody tr:nth-child(even) > :last-child {
	border-radius: 0 10px 10px 0;
}


/* //Marquee Styling  */
.container {
	width: 18em;
	height: 8em;
	margin: 1em auto;
	overflow: hidden;
	background: white;
	position: relative;
	box-sizing: border-box;
}

.marquee {
	top: 6em;
	position: relative;
	box-sizing: border-box;
	animation: marquee 15s linear infinite;
	bottom:2em
}

.marquee:hover {
	animation-play-state: paused;
}
.microsoft:hover {
	animation-play-state: paused;
}
/* Make it move! */
@keyframes marquee {
	0%   { top:   8em }
	100% { top: -11em }
}

/* Make it look pretty */


.microsoft:before, .microsoft::before,
.microsoft:after,  .microsoft::after {
	left: 0;
	z-index: 1;
	content: '';
	position: absolute;
	pointer-events: none;
	width: 100%; height: 2em;
	background-image: linear-gradient(to top, #FFF, rgba(255,255,255,0));
}

.microsoft:after, .microsoft::after {
	bottom: 0;
	transform: rotate(180deg);
}

.microsoft:before, .microsoft::before {
	top: 0;
}

/* Style the links */
.vanity {
	color: #333;
	text-align: center;
	font: .75em 'Segoe UI', Tahoma, Helvetica, Sans-Serif;
}

.vanity a, .microsoft a {
	color: #1570A6;
	transition: color .5s;
	text-decoration: none;
}

.vanity a:hover, .microsoft a:hover {
	color: #F65314;
}

/* Style toggle button */
.toggle {
display: block;
	margin: 2em auto;
}